import React from "react";
import Fade from "react-reveal/Fade";

const textContent = {
  en: {
    awardContnet: [
      {
        img: "Gold",
        awardName: "Govt and commercial companies",
        awardFor: "designed to control quality and productivity",
      },
      {
        img: "Silver",
        awardName: "Medium enterprises",
        awardFor: "designed to increase growth and progression",
      },
      {
        img: "Bronze",
        awardName: "Startups and individuals",
        awardFor: "designed for a unique and strong start",
      }
    ]
  },
  ar: {
    awardContnet: [
      {
        img: "Gold",
        awardName: " الشركات الحكومية والتجارية",
        awardFor: " صممت لضبط الجودة والإنتاجية ",
      },
      {
        img: "Silver",
        awardName: "المنشآت المتوسطة",
        awardFor: "صممت لزيادة النُمو والتقدم ",
      },
      {
        img: "Bronze",
        awardName: "المؤسسات الناشئة والأفراد ",
        awardFor: "صممت للبداية القوية والتميُز ",
      },
      
    ]
  }
}
const Awards = (prods) => {
  const { language } = prods;
  return (
    <>
      <div className="row">
      {textContent[language].awardContnet.map((val, i) => (
          <div className="col-lg-4 m-15px-tb" key={i}>
            <div className="feature-box-02 d-flex align-items-center">
              <Fade bottom>
                <div className="icon">
                <img src={`img/icons/packages/${val.img}.png`} alt={val.awardName} />
                </div>
                <div className="media-body">
                  <h6>{val.awardName}</h6>
                  <p>{val.awardFor}</p>
                </div>
              </Fade>
            </div>
          </div>
          // End .col
        ))}
      </div>
      {/* End .row */}
    </>
  );
};

export default Awards;

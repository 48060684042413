import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import Fade from "react-reveal/Fade";
// var nodemailer = window.require('nodemailer');
import emailjs from 'emailjs-com';
const textContent = {
  en: {
    name:'Name is required',
    placeName:'Full Name',
    email:'Email is Required',
    placeEmail:'Email',
    subject:'Subject is required',
    placeSubject:'Subject',
    comment:'Comment is required',
    placeComment: 'Type Comment',
    validEmail:"Entered value does not match email format",
    send:"Send Message",
  },
  ar: {
    name:'الاسم مطلوب',
    placeName:'الاسم بالكامل',
    email:'البريد الالكتروني مطلوب',
    placeEmail:'البريد الالكتروني',
    subject:'العنوان مطلوب',
    placeSubject:'العنوان',
    comment:'تفاصيل الرسالة',
    placeComment: 'نوع الرسالة',
    validEmail:"البريد الالكتروني غير صحيح",
    send:"ارسال",
  }
};

const Contact = (prods) => {
  const { language } = prods;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const form = useRef();
  const [isSend, setIsSend] = React.useState(false);
  const onSubmit = (data, e) => {
    if (isSend) { 
      alert('Send Mail is Success');
    }
    console.log("Message submited: " + JSON.stringify(data));
    
    e.preventDefault();

    emailjs.sendForm('service_lsrr079', 'template_dcvpajz', form.current, 'jNuZPmTQGr5MjiQrk')
      .then((result) => {
        console.log(result.text);
        setIsSend(true)
      }, (error) => {
          console.log(error.text);
      });
    
    // let mailTransporter = nodemailer.createTransport({
    //   host: "smtp.titan.email",
    //   port: 465,
    //   auth: {
    //     user: "info@albayeen.com",
    //     pass: "Nad2be66ie"
    //   }
    // });

    // // setting credentials
    // let mailDetailsSaad = {
    //   from: "info@albayeen.com",
    //   to: "tigertheblack2000@gmail.com",
    //   subject: e.subject,
    //   html: e.comment,
    // };

    // // sending email
    // Promise.all([
    //   mailTransporter.sendMail(mailDetailsSaad),
    // ])
		// .then((res) => e.target.reset(),console.log('--send mail--') , setIsSend(true))
    //   .catch((err) => console.log(err));
    
  };

  return (
    <>
      <form ref={form} onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-6">
            <Fade bottom>
              <div className="form-group mb-3">
                <input
                  type="text"
                  className="form-control theme-light"
                  placeholder={textContent[language].placeName}
                  {...register("name", { required: true })}
                />
                {errors.name && errors.name.type === "required" && (
                  <span className="invalid-feedback">{textContent[language].name}</span>
                )}
              </div>
            </Fade>
          </div>
          {/* End .col-6 */}

          <div className="col-md-6">
            <Fade bottom>
              <div className="form-group mb-3">
                <input
                  type="email"
                  className="form-control theme-light"
                  placeholder={textContent[language].placeEmail}
                  {...register(
                    "email",
                    {
                      required: textContent[language].placeEmail,
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: textContent[language].validEmail,
                      },
                    },
                    { required: true }
                  )}
                />
                {errors.email && (
                  <span className="invalid-feedback">
                    {errors.email.message}
                  </span>
                )}
              </div>
            </Fade>
          </div>
          {/* End .col-6 */}

          <div className="col-12">
            <Fade bottom>
              <div className="form-group mb-3">
                <input
                  type="text"
                  className="form-control theme-light"
                  placeholder={textContent[language].placeSubject}
                  {...register("subject", { required: true })}
                />
                {errors.subject && (
                  <span className="invalid-feedback">{textContent[language].subject}</span>
                )}
              </div>
            </Fade>
          </div>
          {/* End .col-12 */}

          <div className="col-12">
            <Fade bottom>
              <div className="form-group mb-3">
                <textarea
                  rows="4"
                  className="form-control theme-light"
                  placeholder={textContent[language].placeComment}
                  {...register("comment", { required: true })}
                ></textarea>
                {errors.comment && (
                  <span className="invalid-feedback">{ textContent[language].comment}</span>
                )}
              </div>
            </Fade>
          </div>
          {/* End .col-12 */}
                  {isSend && <div className="alert alert-success">Success Send Mail To Sales</div>}
          <div className="col-12">
            <Fade bottom>
              <div className="btn-bar">
              <button className="px-btn px-btn-white">{ textContent[language].send}</button>
              </div>
            </Fade>
          </div>
          {/* End .col-12 */}
        </div>
      </form>
    </>
  );
};

export default Contact;

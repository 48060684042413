import React from "react";
import Fade from "react-reveal/Fade";

const textContent = {
  en: {
    serviceContent : [
      {
        icon: "Cyber-security",
        title: "Cyber ​​security",
        descriptions: `Since cybersecurity risks are at the forefront of most companies' priorities, Quality Scope develops solutions, evaluates the company's cybersecurity strategy, tests vulnerabilities, outlines areas for improvement, and develops a comprehensive plan, from filling gaps to addressing potential breaches and intercepting attacks.`,
      },
      {
        icon: "Technique-consulting",
        title: "Technique consulting",
        descriptions: `Providing integrated technique consulting for institutions, companies, and government agencies by contributing to the formation and formulation of the technique culture of the institution.`,
      },
      {
        icon: "Legal-consulting",
        title: "Legal consulting",
        descriptions: `We provide legal consulting by connecting clients who seek advice with a group of lawyers in an interactive, easy, safe, and professional way based on laws, while preserving the confidentiality and privacy of customer data.`,
      },
      {
        icon: "Marketing-consulting",
        title: "Marketing consulting",
        descriptions: `Most companies need marketing advice at least once.  We seek to achieve positive results based on planning skill, which ensures the continuity of the largest operations and their successful development to increase investment returns and investment opportunities according to a specific methodology.`,
      },
      {
        icon: "Marketing-solutions",
        title: "Marketing solutions",
        descriptions: `Our team of marketing experts uses sophisticated methods to ensure proper exposure online on the right platforms.
        A custom-designed template, professional copywriting services, and comprehensive analytics tracking and reporting are included.
        We build and manage high-performance programs that produce real results.
        `,
      },
      {
        icon: "Application-development-solutions",
        title: "Application development solutions",
        descriptions: `We are always in a state of development, and the demand for applications that can run on the platforms is increasing among the new generation. We are ready to help you by designing applications that suit your business.`,
      },
      {
        icon: "Mobile-application-development",
        title: "Mobile application development",
        descriptions: `We offer user-centered IOS app development services, including designing and coding apps for the iPhone, iPad, and other Apple devices, as well as back-end apps and IOS tools that take advantage of the platform's latest features and provide cutting-edge Android app design and development services tailored to our clients' individual needs.`,
      },
      {
        icon: "Web-development-solutions",
        title: "Web development solutions",
        descriptions: `In conjunction with the technical development, we have various web development services, and we also create powerful internet and e-business applications for your company so that you are always aware of your business.`,
      },
      {
        icon: "Website-design",
        title: "Website design",
        descriptions: `We create high-quality, professional websites. It is the process of planning and building elements for your site, from the structure and layout to images, colors, fonts, and graphics, to be the strongest among your competitors.`,
      },
    

    ]
  },
  ar: {
    serviceContent : [
      {
        icon: "Cyber-security",
        title: "الأمن السيبراني",
        descriptions: `بما أن مخاطر الأمن السيبراني تقع في مقدمة أولويات معظم الشركات، تضع كواليتي سكوب الحلول و تقييم إستراتيجية الأمن السيبراني للشركة، وتختبر الثغرات وتوضح المناطق المطلوب تحسينها، وتضع خطة شاملة بداً بسد الثغرات  الى معالجة الاختراقات المحتملة واعتراض الهجمات.`,
      },
      {
        icon: "Technique-consulting",
        title: "الاستشارات التقنية",
        descriptions: `شركتنا توفير استشارات تقنية متكاملة للمؤسسات والشركات والهيئات الحكومية وذلك من خلال المساهمة في تشكيل وصياغة ثقافة المؤسسة تقنيا.`,
      },
      {
        icon: "Legal-consulting",
        title: "الاستشارات القانونية",
        descriptions: `نقدم الاستشارات القانونية من خلال ربط العملاء طالبي الاستشارة بنخبة من المحامين بطريقة تفاعلية وسهلة وآمنة ومهنية استنادا الى القوانين مع الحفاظ على سرية وخصوصية بيانات العملاء.`,
      },
      {
        icon: "Marketing-consulting",
        title: "الاستشارات التسويقية",
        descriptions: ` معظم الشركات تحتاج إلى استشارة تسويقية لمرة واحدة على الأقل نسعى لتحقيق نتائج ايجابية قائمة على مهارة التخطيط مما يحقق استمرار أكبر العمليات ويكفل تطويرها بنجاح لزيادة عائد الاستثمار والفرص الاستثمارية وفق منهجية محددة.`,
      },
      {
        icon: "Marketing-solutions",
        title: "حلول التسويق",
        descriptions: `يستخدم فريق خبراء التسويق التابع لنا أساليب متطورة لضمان حصول شركتك على عرض مناسب عبر الإنترنت على المنصات المناسبة يتم تضمين قالب مصمم خصيصًا وخدمات كتابة النصوص الاحترافية وتتبع التحليلات الشاملة وإعداد التقارير.
        و نقوم ببناء وإدارة برامج عالية الأداء والمصاحبة بالنتائج الحقيقية.        
        `,
      },
      {
        icon: "Application-development-solutions",
        title: "حلول تطوير التطبيقات",
        descriptions: `نحن دائما في حالة تطور، يزداد الطلب على التطبيقات التي يمكن تشغيلها على الأنظمة الاساسية بين الجيل الجديد نحن مستعدون لمساعدتك من خلال تصميم تطبيقات تناسب عملك.`,
      },
      {
        icon: "Mobile-application-development",
        title: "تطوير تطبيقات الجوال",
        descriptions: `نحن نقدم خدمات تطوير تطبيقات IOS التي تركز على المستخدم، بما في ذلك تصميم التطبيقات وترميزها لأجهزة iPhone وiPad وأجهزة Apple الأخرى، بالإضافة إلى التطبيقات الخلفية وأدوات IOS التي تستفيد من أحدث ميزات النظام الأساسي و نقدم تصميم تطبيقات Android المتطورة وخدمات التطوير المصممة خصيصًا لتلبية الاحتياجات الفردية لعملائنا.`,
      },
      {
        icon: "Web-development-solutions",
        title: "حلول تطوير الويب",
        descriptions: `بالتزامن مع التطور التقني، لدينا خدمات تطوير الويب المختلفة و نقوم ايضا بإنشاء تطبيقات الانترنت والأعمال التجارية الالكترونية القوية لشركتك بحيث تكون دائمًا على اطلاع بعملك.`,
      },
      {
        icon: "Website-design",
        title: "تصميم موقع الكتروني",
        descriptions: `نحن نصمم لك موقع بأعلى جودة وبشكل احترافي انها عملية تخطيط وبناء عناصر للموقع الخاص بك من الهيكل والتخطيط الى الصور والالوان والخطوط والرسومات لتكون الاقوى بين منافسيك.`,
      },
    
    ]
  }
}

export default function Service(prods) {
    const { language } = prods;
  return (
    <>
      <div className="row">
        {textContent[language].serviceContent.map((val, i) => (
          <div className="col-md-6 col-lg-4 my-3" key={i}>
            <div className="feature-box-01 ">
              <Fade bottom>
                <div className="icon">
                  <img src={`./img/icons/${val.icon}.png`} width={62} />
                </div>
                <div className="feature-content">
                  <h5>{val.title}</h5>
                  <p>{val.descriptions}</p>
                </div>
              </Fade>
            </div>
            {/* End .feature-box-01 */}
          </div>
        ))}
      </div>
    </>
  );
}

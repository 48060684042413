import React from "react";
import Social from "../Social";
import Testimonials from "../testimonial/TestimonialAnimation";
import Services from "../service/ServiceAnimation";
import Awards from "../award/AwardsAnimation";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";


const textContent = {
  en: {
    info: {
      head: 'Founder, President, and Chairman',
      body: 'Dr. Abdallah Abakar'
    },
    title: 'About us',
    body: `
    Our company supports clients around the world in achieving spread through various digital marketing platforms and building a strong community via the Internet. 
    In addition to designing websites, mobile applications and web development, we overcome the barriers and obstacles that you face.
    `,
    body2: `All this through an experienced team <br />
    Our goal is your success.
    `,
   
    outService: "out Service",
    packages: "Packages",
    OurTeam:"Our Team",
    contact: "Contact",
  },
  ar: {
    info: {
      head: 'المؤسس والرئيس ورئيس مجلس الإدارة',
      body: 'د/ عبدالله أبكر'
    },
    title: 'من نحن',
    body: `شركتنا تدعم العملاء في تحقيق الانتشار عبر منصات التسويق الرقمي المختلفة وبناء مجتمع قوي عبر شبكة الانترنت بالإضافة إلى تصميم المواقع وتطبيقات الجوال وتطوير شبكة الويب نحن نتغلب على الحواجز والعقبات التي تواجهها  
    `,
    body2: `كل ذلك  من خلال فريق ذو خبرة <br />
    هدفنا هو نجاحك 
    `,

    outService:"خدماتنا" ,
    packages: "الباقات",
    OurTeam: "فريقنا",
    work: "العمل",
    resume: "سيرة ذاتية",
  }
};

const About = (prods) => {
  const { language } = prods;

  return (
    <>
      <section id="about" className="section theme-light dark-bg">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-6 col-lg-4">
              <div className="about-me">
                <div className="img">
                  <div className="img-in">
                    <img src="img/about/about-me.png" alt="about image" />
                  </div>
                  <Zoom>
                    <Social />
                  </Zoom>
                  {/* End social icon */}
                </div>
                {/* End img */}
                <div className="info">
                  
                </div>
                {/* End info */}
              </div>
              {/* End about-me */}
            </div>
            {/* End col */}

            <div className="col-lg-7 ml-auto">
              <div className="about-info">
                <div className="title">
                  <h3>{textContent[language].title}</h3>
                </div>
                <div className="about-text">
                  <Fade bottom>
                    <p>
                      {textContent[language].body}
                    </p>
                    <p dangerouslySetInnerHTML={{__html: textContent[language].body2}}>
                     
                    </p>
                  </Fade>
                </div>
                <div className="info-list">
                  <Fade bottom>
                    <div className="row">
                      <div className="col-sm-6">
                      <Fade bottom>
                        <p>{textContent[language].info.head}</p>
                        <a href="https://www.linkedin.com/in/dr-abdallah-abakar"><h3  className="text-admin">{textContent[language].info.body}</h3></a>
                      </Fade>
                      </div>
                      <div className="col-sm-6">
                        
                      </div>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
            {/* End col */}
          </div>

          {/* separated */}

          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>

          {/* End separated */}
          <div className="title">
            <h3>{textContent[language].outService}</h3>
          </div>

          <Services  language={language}  />

          {/* End .row */}

          {/* separated */}
          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>
          {/* End separated */}

          <div className="title">
            <h3>{textContent[language].packages}</h3>
          </div>

          <Awards language={language} />
          {/* End Awards */}

          {/* separated */}
          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>
          {/* End separated */}

          <div className="title">
            <h3>{textContent[language].OurTeam}</h3>
          </div>

          <Testimonials language={language} />
          {/* End Testimonaial */}
        </div>
      </section>
    </>
  );
};

export default About;

import React, { useEffect, useState,useRef} from "react";
import Fade from "react-reveal/Fade";
const axios = require('axios').default;

const Map = () => {
  const [countryCode,setCountry]=  useState('SA');
  const [cityName, setcityName] = useState('Jeddah');
  const center = { lat: -34.397, lng: 150.644 };
  const zoom = 4;
 
 const  loadinmap=()=>{
    axios.get('https://api.db-ip.com/v2/free/self')
      .then(function (respornse) {
        setCountry(respornse.data.countryCode == 'LCY' ? respornse.data.countryCode : "SA");
        setcityName(respornse.data.city);
      })
      .catch(function (error) {
        console.log(error);
      });
    console.log('loadingmap ');
  }
  useEffect(() => {
    loadinmap();
    return () => {
      
    }
  }, []);

  return (
    <>
      <div className="google-map">
        <Fade>
      
          <div className="embed-responsive embed-responsive-21by9">
            {
              (countryCode == 'LCY') &&
                <iframe className="embed-responsive-item" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.2285469593257!2d-0.08672711560797618!3d51.527367817064246!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761d429f1982db%3A0xae34b864089f4ebc!2sA%20QUALITY%20SCOPE%20LTD!5e0!3m2!1sar!2seg!4v1677970632710!5m2!1sar!2seg"  height={300} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              ||
              (cityName == 'riyadh') &&
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7431.612054485208!2d39.8967366!3d21.358145!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c205b254ca958b%3A0x53fd15a7cc5f2bce!2sA%20QUALITY%20SCOPE%20LTD!5e0!3m2!1sar!2seg!4v1681902211897!5m2!1sar!2seg" height={300}  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              ||
              (cityName == 'Jeddah') &&
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7431.612054485208!2d39.8967366!3d21.358145!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c205b254ca958b%3A0x53fd15a7cc5f2bce!2sA%20QUALITY%20SCOPE%20LTD!5e0!3m2!1sar!2seg!4v1681902211897!5m2!1sar!2seg" height={300}  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>    
              ||
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7431.612054485208!2d39.8967366!3d21.358145!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c205b254ca958b%3A0x53fd15a7cc5f2bce!2sA%20QUALITY%20SCOPE%20LTD!5e0!3m2!1sar!2seg!4v1681902211897!5m2!1sar!2seg" height={300}  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>    
            }
          </div>
        </Fade>
      </div>
      {/* End google-map */}
    </>
  );
};

export default Map;

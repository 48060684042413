import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Fade from "react-reveal/Fade";

export default function SimpleSlider(prods) {
  const { language } = prods;
  
  const TestimonilContent = {
    en: 
      [
        {
          imageName: "CEO",
          desc: ``,
          reviewerName: "word of chief executive officer",
          designation: "The passion for excellence and leadership is an obsession that always drives us to soar to new horizons.",
        },
        {
          imageName: "CTO",
          desc: ``,
          reviewerName: "Word of the Executive Technical Director",
          designation: "We provide satisfying services to our customers, creating a digital future for ourselves and for generations to come.",
        },
        {
          imageName: "PMP",
          desc: ``,
          reviewerName: "Word of the  Project Management Professional",
          designation: "After we put in a lot of effort and overcame many challenges, we realized an important truth about ourselves that “we can do anything only if we really want to achieve it",
        },
      ],
    ar: [
      {
        imageName: "CEO",
        desc: ``,
        reviewerName: " كلمة الرئيس التنفيذي لشركة CEO",
        designation: "كلما أتذكر كيف بدأنا وكيف إرتقينا وأين نقف الآن أجد نفسي أشعر بالفخر بكل ما حققناه من نجاحات. إن شغف التفوق والريادة هاجسا يدفعنا دومًا للتحليق إلى آفاق جديدة",
      },
      {
        imageName: "CTO",
        desc: ``,
        reviewerName: "كلمة المدير التقني التنفيذي :CTO",
        designation: " نحن نقدم خدمات وحلول تحقق الرضا لعملائنا، ونسخر قدراتنا وخبراتنا لنبني مجتمعًا معرفيا يواكب العالم، ونبتكر مستقبلًا رقميًا لنا وللأجيال القادمة",
      },
      {
        imageName: "PMP",
        desc: ``,
        reviewerName: "كلمة محترف إدارة المشاريع : PMP",
        designation: "بعد أن قمنا ببذل جهد كبير والتغلب على العديد من التحديات ، أدركنا حقيقة هامة عن أنفسنا وهي "+
        "“أننا نستطيع القيام بأي شيء فقط إذا أردنا بالفعل تحقيقه“",
      },
    ],
  }
  const settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 900,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    margin: 30,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

 

  return (
    <div className="testimonial-wrapper">
      <Slider {...settings}>
        {TestimonilContent[language].map((val, i) => (
          <div key={i}>
            <div className="testimonial-01 media">
              <Fade bottom>
                <div className="avatar">
                  <img
                    src={`img/icons/ourteam/${val.imageName}.png`}
                    alt="review comments"
                  ></img>
                </div>
                <div className="media-body">
                  <p>{val.desc}</p>
                  <h6>{val.reviewerName}</h6>
                  <span>{val.designation}</span>
                </div>
              </Fade>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

import React, { useEffect, useState, } from "react";

import Header from "../../components/header/Header";
import Slider from "../../components/slider/SliderAnimation";
import About from "../../components/about/AboutAnimation";
import Resume from "../../components/resume/ResumeAnimation";
import Portfolio from "../../components/portfolio/PortfolioAnimation";
import Blog from "../../components/blog/BlogAnimation";
import Contact from "../../components/contact/ContactAnimation";
import ContactInfo from "../../components/contact/ContactInfoAnimation";
import Map from "../../components/contact/MapAnimation";
import Footer from "../../components/footer/FooterAnimation";
import useDocumentTitle from "../../components/useDocumentTitle";
import {
  useParams
} from "react-router-dom";
const textContent = {
  en: {
    portfolio:'Our work',
    latestBlog: "Latest Blog",
    contactUs: "Contact Us",
  },
  ar: {
    portfolio:'الاعمال',
    latestBlog: "اخر المقالات",
    contactUs: "تواصل معنا",
  }
};



const HomeOne = (props) => {
  let {lang} = useParams();

  let [language, setLanguage] = useState("en");
  useEffect(() => {
    setLanguage(lang ? lang : "en");
    console.log(lang);
    if(lang=='ar')
      document.body.classList.add("dirRtl");
    return (() => {
      console.log(lang,'loading end locale ');
    });
  }, []);

  useDocumentTitle(
    language=='ar'?'Software Company | شركة برمجة | A Quality Scope Lt':'Software Company | شركة برمجة | A Quality Scope Lt'
  );
  document.body.classList.add("theme-light");
  return (
    <div className="main-left">
      <Header  language={language} />
      {/* End Header Section */}
      
      <Slider
       language={language}
      />
      {/* End Slider Section */}

      <About  language={language} />
      {/* End About Section */}

      <Resume  language={language} />
      {/* End Resume Section */}

      <section id="work" className="section theme-light dark-bg">
        <div className="container">
          <div className="title">
            <h3>{textContent[language].portfolio}</h3>
          </div>
          <Portfolio  language={language} />
        </div>
      </section>
      {/* End Portfolio Section */}

      <section id="blog" className="section">
        <div className="container">
          <div className="title">
            <h3>{textContent[language].latestBlog}</h3>
          </div>
          <Blog  language={language} />
        </div>
      </section>
      {/* End Portfolio Section */}

      <section id="contactus" className="section theme-light dark-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-xl-4 m-15px-tb">
              <ContactInfo  language={language} />
            </div>
            {/* End Contact info */}

            <div className="col-lg-7 ml-auto m-15px-tb  ">
              <div className="contact-form">
                <h4>{textContent[language].contactUs}</h4>
                <Contact  language={language} />
              </div>
            </div>
            {/* End contact form */}

            <div className="col-12">
              <Map  language={language} />
              {/* End google-map */}
            </div>
            {/* End Col */}
          </div>
        </div>
      </section>
      {/* End Contact Section */}

      <footer className="footer white">
        <div className="container">
          <Footer   language={language} />
        </div>
      </footer>
     
    </div>
  );
};

export default HomeOne;
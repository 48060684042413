import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import { FiPlus } from "react-icons/fi";
import Masonry from "react-masonry-css";
import Fade from "react-reveal/Fade";

const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  700: 2,
  500: 1,
};
const textContent = {
  en: {
    title:"Our work",
    all: 'All',
    tabs: [
      {
        title: 'All',
        key:'all',
        list: [
          {title:'social media Marketing',img:'marketing/social media Marketing.png'},
          {title:'Email marketing',img:'marketing/Email marketing.png'},
          {title:'Affiliate marketing',img:'marketing/Affiliate marketing.png'},
          {title:'SEO improvement',img:'marketing/SEO improvement.png'},
          { title: 'Website design', img: 'creatingDesigns/Website design.png' },
          {title:'Web development',img:'development/Web development.png'},
          {title:'Mobile application development',img:'development/Mobile application development.png'},
          { title: 'Database development', img: 'development/Database development.png' },
          {title:'Website design',img:'creatingDesigns/Website design.png'},
          {title:'Android application design',img:'creatingDesigns/Android application design.png'},
          {title:'Apple application design',img:'creatingDesigns/Apple application design.png'},
          { title: 'Design creation', img: 'creatingDesigns/Design creation.png' },
          {title:'Technical consulting',img:'consulting/Technical consulting.png'},
          {title:'Marketing consulting',img:'consulting/Marketing consulting.png'},
          {title:'Legal advice',img:'consulting/Legal advice.png'},
        ]
      },
      {
        title: 'Marketing',
        key:'marketing',
        list: [
          {title:'social media Marketing',img:'marketing/social media Marketing.png'},
          {title:'Email marketing',img:'marketing/Email marketing.png'},
          {title:'Affiliate marketing',img:'marketing/Affiliate marketing.png'},
          {title:'SEO improvement',img:'marketing/SEO improvement.png'},
          {title:'Website design',img:'creatingDesigns/Website design.png'},
        ]
      },
      {
        title: 'Consulting',
        key:'consulting',
        list: [
          {title:'Technical consulting',img:'consulting/Technical consulting.png'},
          {title:'Marketing consulting',img:'consulting/Marketing consulting.png'},
          {title:'Affiliate marketing',img:'marketing/Affiliate marketing.png'},
          {title:'Legal advice',img:'consulting/Legal advice.png'},
        ]
      },
      {
        title: 'Development',
        key:'development',
        list: [
          {title:'Web development',img:'development/Web development.png'},
          {title:'Mobile application development',img:'development/Mobile application development.png'},
          {title:'Database development',img:'development/Database development.png'},
        ]
      },
      {
        title: 'Creating Designs',
        key:'creatingDesigns',
        list: [
          {title:'Website design',img:'creatingDesigns/Website design.png'},
          {title:'Android application design',img:'creatingDesigns/Android application design.png'},
          {title:'Apple application design',img:'creatingDesigns/Apple application design.png'},
          {title:'Design creation',img:'creatingDesigns/Design creation.png'},
        ]
      },
    ],
  },
  ar: {
    title:"اعمالنا",
 
    tabs: [
      {
        title: 'الكل',
        key:'all',
        list: [
          {title:'التسويق عبر التواصل الاجتماعي',img:'marketing/social media Marketing.png'},
          {title:'التسويق عبر البريد الالكتروني',img:'marketing/Email marketing.png'},
          {title:'التسويق بالعمولة',img:'marketing/Affiliate marketing.png'},
          { title: 'SEO تحسين', img: 'marketing/SEO improvement.png' },
          {title:'الاستشارات التقنية',img:'consulting/Technical consulting.png'},
          {title:'الاستشارات التسويقية',img:'consulting/Marketing consulting.png'},
          { title: 'الاستشارات القانونية', img: 'consulting/Legal advice.png' },
          {title:'تطوير شبكة الويب',img:'development/Web development.png'},
          {title:'تطوير تطبيقات الهاتف',img:'development/Mobile application development.png'},
          { title: 'تطوير قواعد البيانات', img: 'development/Database development.png' },
          {title:'تصميم الموقع الالكتروني',img:'creatingDesigns/Website design.png'},
          {title:'تصميم تطبيقات اندرويد',img:'creatingDesigns/Android application design.png'},
          {title:'تصميم تطبيقات ابل',img:'creatingDesigns/Apple application design.png'},
          {title:'إنشاء التصميم',img:'creatingDesigns/Design creation.png'},
        ]
      },
      {
        title: 'التسويق',
        key:'marketing',
        list: [
          {title:'التسويق عبر التواصل الاجتماعي',img:'marketing/social media Marketing.png'},
          {title:'التسويق عبر البريد الالكتروني',img:'marketing/Email marketing.png'},
          {title:'التسويق بالعمولة',img:'marketing/Affiliate marketing.png'},
          {title:'SEO تحسين',img:'marketing/SEO improvement.png'},
        ]
      },
      {
        title: 'الإستشارات',
        key:'consulting',
        list: [
          {title:'الاستشارات التقنية',img:'consulting/Technical consulting.png'},
          {title:'الاستشارات التسويقية',img:'consulting/Marketing consulting.png'},
          {title:'لاستشارات القانونية',img:'consulting/Legal advice.png'},
        ]
      },
      {
        title: 'التطوير',
        key:'development',
        list: [
          {title:'تطوير شبكة الويب',img:'development/Web development.png'},
          {title:'تطوير تطبيقات الهاتف',img:'development/Mobile application development.png'},
          {title:'تطوير قواعد البيانات',img:'development/Database development.png'},
        ]
      },
      {
        title: 'إنشاء التصميمات',
        key:'creatingDesigns',
        list: [
          {title:'تصميم الموقع الالكتروني',img:'creatingDesigns/Website design.png'},
          {title:'تصميم تطبيقات اندرويد',img:'creatingDesigns/Android application design.png'},
          {title:'تصميم تطبيقات ابل',img:'creatingDesigns/Apple application design.png'},
          {title:'إنشاء التصميم',img:'creatingDesigns/Design creation.png'},
        ]
      },
    ],
   
  }
};
const Portfolio =  (prods) => {
  const { language } = prods;
  return (
    <SimpleReactLightbox>
      <div className="portfolio-filter-01">
        <Tabs>
          <TabList className="filter d-flex flex-wrap justify-content-start">
          
            {textContent[language].tabs.map((tap,index) => <Tab key={'portfolio'+(index)}>{tap.title}</Tab>)}
            
          </TabList>
          {/* End tablist */}

          <SRLWrapper>
         
            {textContent[language].tabs.map((tap,index) =>
              <TabPanel key={tap.key + 'tap' + index}>
              <div className="portfolio-content ">
                <Masonry
                  breakpointCols={breakpointColumnsObj}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  {tap.list.map((item,i) =>
                    <div key={item.key + 'img' + i} className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5>{item.title}</h5>
                          <span></span>
                        </div>
                        {/* End .portfolio-info */}
                        <a
                          href={"./img/ourwork/" + item.img}
                          className="gallery-link"
                        >
                          <Fade>
                            <img
                              src={"./img/ourwork/" + item.img}
                              alt={item.title}
                            />
                          </Fade>
                        </a>
                        {/* End gallery link */}

                        <div className="portfolio-icon">
                          <FiPlus />
                        </div>
                        {/* End .portfolio-icon */}
                      </div>
                    </div>
                  )}
                  {/* grid item  */}

                  {/* grid item  */}
                </Masonry>
              </div>
              {/* End list wrapper */}
            </TabPanel>)}

          </SRLWrapper>
          {/* End tabpanel */}
        </Tabs>
      </div>
    </SimpleReactLightbox>
  );
};

export default Portfolio;

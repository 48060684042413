import React from "react";
import Routes from "./router/Routes";
import ScrollToTop from "./components/ScrollToTop";
import AnimatedCursor from "react-animated-cursor"

const App = () => {
  return (
    <>
      <ScrollToTop />
      <Routes />
      {/* End Contact Section */}
      <AnimatedCursor
        innerSize={12}
        outerSize={50}
        color="100, 172, 231"
        outerAlpha={0.2}
        innerScale={0.7}
        outerScale={1}
        clickables={[
          'a',
          'input[type="text"]',
          'input[type="subject"]',
          'input[type="message"]',
          'input[type="number"]',
          'input[type="submit"]',
          'input[type="image"]',
          'label[for]',
          'select',
          'textarea',
          'button',
          '.link'
        ]}
      />
    </>
  );
};

export default App;

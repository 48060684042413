import React from "react";
import Skills from "../skills/Skills";
import Fade from "react-reveal/Fade";


const textContent = {
  en: {
    title:'Events',
    resumeContent : [
      {
        jobPosition: `The company's head office`,
        jobType: `Britain | London`,
        jobDuration: `June-2021`,
        timeDuraton: ``,
        compnayName: `A Quality Scope Ltd`,
        jobDescription: `Dr. Abdallah Abakar opened A Quality Scope Ltd in 2021 in Britain, in London, and it is a company that provides various services such as designing websites and applications, providing technical, marketing and legal consultancy and other technology services.`,
      },
      {
        jobPosition: `Mcnuun`,
        jobType: `Saudi Arabia | Riyadh `,
        jobDuration:`Feb-2021`,
        timeDuraton: ``,
        compnayName: `Mcnuun platform`,
        jobDescription: `Mr. Abdulelah Ibrahim, CEO of A Quality Scope Ltd, inaugurated Mcnuun, an electronic marketing platform for products.`,
      },
      {
        jobPosition: `Branch of the company in  Saudi Arabia`,
        jobType: `Saudi Arabia | Mecca`,
        jobDuration: `January - 2023`,
        timeDuraton:``,
        compnayName: "A Quality Scope Ltd",
        jobDescription:`In 2023, the CEO of A Quality Scope Ltd, Mr.  Abdulelah Ibrahim, opened the second branch of the company in Saudi Arabia, in Riyadh.`,
      },
    ]
  },
  ar: {
    title: 'الاحداث',
    resumeContent: [
    {
      jobPosition: `المقر الرئيسي للشركة`,
      jobType: `بريطانيا | لندن`,
      jobDuration: `يونيو - 2021`,
      timeDuraton: ``,
      compnayName: "كواليتي سكوب",
      jobDescription: `افتتح الدكتور عبدالله أبكر شركة كوالتي سكوب عام ٢٠٢١ في بريطانيا بمدينة لندن وهي شركة تقدم خدمات متنوعة مثل تصميم المواقع والتطبيقات وتقديم الاستشارات التقنية والتسويقية والقانونية وغيرها من الخدمات التكنولوجيا `,
    },
    {
      jobPosition: `مكنون`,
      jobType: `السعودية | الرياض`,
      jobDuration: `فبراير - 2022`,
      timeDuraton: ``,
      compnayName: "منصة مكنون",
      jobDescription: `افتتح المدير التنفيذي لشركة كواليتي سكوب الأستاذ عبد الإله إبراهيم موقع مكنون، وهي منصة تسويق الكتروني للمنتجات.`,
    },
    {
      jobPosition:"مقر الشركة بالمملكة العربية السعودية" ,
      jobType: `السعودية | مكة المكرمة`,
      jobDuration: `يناير - 2023`,
      timeDuraton: ``,
      compnayName: `كواليتي سكوب`,
      jobDescription: `افتتح المدير التنفيذي لشركة كواليتي سكوب الأستاذ عبدالإله  عام 2023 الفرع الثاني لشركة في السعودية بمدينة الرياض.`,
    },
  ]}
}


const educatonContent = [
  {
    passingYear: "2018-2020",
    degreeTitle: "Ph.D in Horriblensess",
    instituteName: "University Of Evil Doing",
  },
  {
    passingYear: "2013-2016",
    degreeTitle: "Bsc. in Computer Science",
    instituteName: "World University",
  },
  {
    passingYear: "2010-2012",
    degreeTitle: "Graphic Artist Training ",
    instituteName: "Graphic Master Institute",
  },
];

const Resume = (prods) =>{

  const { language } = prods;
  return (
    <>
      <section id="resume" className="section">
        <div className="container">
          <div className="title">
            <h3>{ textContent[language].title}</h3>
          </div>
          {/* End title */}
          <div className="resume-box">
          {textContent[language].resumeContent.map((val, i) => (
              <Fade bottom key={i}>
                <div className="resume-row">
                  <div className="row">
                    <div className="col-md-4 col-xl-3">
                      <div className="rb-left">
                        <h6>{val.compnayName}</h6>
                        <label>{val.jobType}</label>
                        <p>{val.jobDuration}</p>
                        <div className="rb-time">{val.timeDuraton}</div>
                      </div>
                    </div>
                    <div className="col-md-8 col-xl-9">
                      <div className="rb-right">
                        <h6>{val.jobPosition}</h6>
                        <p>{val.jobDescription}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Fade>
            ))}
          </div>

          {/* separated */}
          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>
          {false && 

          <div>
          <div className="title">
            <h3>Education & Skills</h3>{" "}
          </div>

          <div className="row align-items-center">
            <div className="col-lg-4 m-15px-tb">
              <ul className="aducation-box">
                {educatonContent.map((val, i) => (
                  <li key={i}>
                    <Fade bottom>
                      <span>{val.passingYear}</span>
                      <h6>{val.degreeTitle} </h6>
                      <p>{val.instituteName}</p>{" "}
                    </Fade>
                  </li>
                ))}
              </ul>
            </div>
            {/* End .col */}

            <div className="col-lg-7 ml-auto m-15px-tb">
              <Fade>
                <Skills />{" "}
              </Fade>
                </div>
            {/* End .col */}
          </div></div>
            }
        </div>
      </section>
    </>
  );
};

export default Resume;

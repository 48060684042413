import React from "react";
import TextLoop from "react-text-loop";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
const axios = require('axios').default;

const conctInfo = {
  phone: "+44 20 328 90900",
  phone2: "+966 5419 54533",
  email: "contact@aqualityscope.com",
};

const sliderContent = {
  en: {
  head:'A Quality Scope Ltd',
  name: "Love with a quality",
  description: `The best place for your business <br />
  We provide all solutions for all businesses through a specialized team to <br />
  ensure that you reach your goal and ensure that you stay in the forefront <br />
  (and ensure you stay ahead). We achieve the best quality, service and the most<br />
   wonderful ideas. We are brilliant at what we do.
  `,
    textAnime: [
      'Websites',
      'Mobile applications',
      'Digital marketing',
      'Technical consulting',
      'Design UI/ UX'
  ],
  btnText: "Order Now",
},ar: {
    head:'كواليتي سكوب',
    name: "الحُب مع الجودة",
    description: `المكان الأفضل لعملك نحن نقدم كافة الحلول لجميع الأعمال<br />
     من خلال فريق مختص للتأكد من الوصول إلى هدفك<br />
      وضمان البقاء في الصدارة  ونحقق أفضل جودة وخدمة وأروع الأفكار<br />
      نحن بارعين فيما نقوم به.`    ,
    textAnime: [
      'المواقع الإلكترونية',
      'تطبيقات الجوال',
      'التسويق الرقمي',
      'الاستشارات التقنية ',
      'تصميم UI/UX'
    ],
    btnText: "أطلب الان",
}};
const Slider = (prods) => {
  const { language } = prods;
  const [settings, setSettings] = React.useState(false);

  const  loadingSetting=()=>{
    axios.get('https://aqualityscope.com/blog/wp-json/wp/v2/home_inputs')
      .then(function (respornse) {
        setSettings(respornse.data);
      })
      .catch(function (error) {
       
      });
    console.log('loadingmap ');
  }
  React.useEffect(() => {
     loadingSetting();
    return () => {
      
    }
  }, []);
 
  return (
    <>
      {/*  Home Banner */}
      <section id="home" className="home-banner">
        <div className="hb-top-fixed d-flex">
          <div className="hb-info">
            <a href="tel:+442032890900">{conctInfo.phone}</a>
            <a href="tel:+966541954533">{conctInfo.phone2}</a>
            <a href="mailto:contact@aqualityscope.com">
              {conctInfo.email}
            </a>
          </div>
          <div className="hb-lang">
            <ul className="nav">
            <li  className={language=='en'?"active":""}>
                <a  href="/"><img width={30} src="./img/icons/location/uk flag.png" /></a>
              </li>
              <li className={language=='ar'?"active":""}>
                <a href="/ar"><img width={30} src="./img/icons/location/KSA flag.png" /></a>
              </li>
            </ul>
          </div>
        </div>
        {/* End hp-top-fixed */}

        <div className="container">
          <div className="row full-screen align-items-center">
            <div className="col-lg-5">
              <div className="type-box">
                <Fade bottom>
                <h6>{ sliderContent[language].name}</h6>
                <h1 className="font-alt">{sliderContent[language].head}</h1>
                  <TextLoop>
                  {sliderContent[language].textAnime.map((item,index) =>
                    <p key={'mann'+index} className="loop-text lead">{item}</p>
                  )}
                  </TextLoop>{" "}
                  <p className="desc" dangerouslySetInnerHTML={{__html: sliderContent[language].description}}></p>
                  <div className="mt-4">
                    <a
                      className="px-btn px-btn-white"
                      href={settings['url']??'#'}
                      download
                    >
                       {sliderContent[language].btnText}
                    </a>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
        {/* End Container*/}
        <div
          // className="hb-me"
          // style={{
          //   backgroundImage: `url(${
          //     process.env.PUBLIC_URL + "img/slider/home-banner.png"
          //   })`,
          // }}
        >
          {settings['video'] != undefined &&
            <iframe className="hb-me"
              src={'https://www.youtube.com/embed/' + settings['video'] + '?controls=0&rel=0&playsinline=1&enablejsapi=1&origin=https%3A%2F%2Faqualityscope.com&widgetid=1&autoplay=1&mute=1'}
               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" frameborder="0"
            autoplay allowfullscreen></iframe>
            ||
            <iframe className="hb-me"
              src={'https://www.youtube.com/embed/h4LV2viNHmk?controls=0&rel=0&playsinline=1&enablejsapi=1&origin=https%3A%2F%2Faqualityscope.com&widgetid=1&autoplay=1&mute=1'}
               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" frameborder="0"
            autoplay allowfullscreen></iframe>
          }
        </div>
      </section>

      {/* End Home Banner  */}
    </>
  );
};

export default Slider;

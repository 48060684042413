import React from "react";
import Fade from "react-reveal/Fade";

const textContent = {
  en: {
    title:'Get In Touch',
    body: "A Quality Scope Ltd is with you past, present and future",
    email: "contact@aqualityscope.com",
    address: `United Kingdom, London `,
    mobile: "44 20 328 90900",
    address2: `Kingdom of Saudi Arabia, Riyadh | Mecca `,
    mobile2: "966 5419 54533",
  },
  ar: {
    title:'ابقى على تواصل',
    body: "  كواليتي سكوب معك في الماضي والحاضر والمستقبل",
    email: "contact@aqualityscope.com",
    address: " المملكة المتحدة , لندن ",
    mobile: "44 20 328 90900",
    address2: " المملكة العربية العربية السعودية ,الرياض | مكة المكرمة ",
    mobile2: "966 5419 54533",
  }
};

 const  ContactInfo = (prods) => {
  const { language } = prods;
  return (
    <>
    <div className="contact-info">
      <Fade bottom>
        <h4>{textContent[language].title}</h4>
        <p>
          {textContent[language].body}
        </p>
      </Fade>
      <ul>
        <Fade bottom>
          <li className="media">
            <img src={'./img/icons/location/map.png'} width={32} />
            <span className="media-body">
              {textContent[language].address}
            </span>
            </li>
            <li className="media">
            <img src={'./img/icons/location/Mobile.png'} width={32} />
            <a href="tel:+966541954533"><span className="media-body">+{textContent[language].mobile}</span></a>
          </li>
          <li className="media">
            <img src={'./img/icons/location/map.png'} width={32} />
            <span className="media-body">
              {textContent[language].address2}
            </span>
          </li>
          <li className="media">
            <img src={'./img/icons/location/Mobile.png'} width={32} />
            <a href="tel:+442032890900"><span className="media-body">+{textContent[language].mobile2}</span></a>
            </li>
            
          <li className="media">
          <img src={'./img/icons/location/Mail.png'} width={32} />
            <a href="mailto::contact@aqualityscope.com"><span className="media-body">{textContent[language].email}</span> </a>
          </li>

        </Fade>
      </ul>
    </div>
  </>
  );
};


export default ContactInfo;

import React, { useEffect, useState} from "react";
import { useParams } from "react-router-dom";
const axios = require('axios').default;

const Map = () => {
 const [countryCode,setCountry]=  useState('SA');
  const [ countryName, setcountryName ] = useState('Saudiarabia');
  
 const  loadinmap=()=>{
    axios.get('https://api.db-ip.com/v2/free/self')
      .then(function (respornse) {
        setCountry(respornse.data.countryCode == 'LCY' ? respornse.data.countryCode : "SA");
        setcountryName(respornse.data.countryCode );
      })
      .catch(function (error) {
        console.log(error);
      });
    console.log('loadingmap ');
  }
  useEffect(() => {
    loadinmap();
    return () => {
      
    }
  }, []);

  return (
    <>
      <div>{countryName}</div>
      <div className="google-map">
        <div className="embed-responsive embed-responsive-21by9">
            {(countryCode == 'LCY') &&
              <iframe className="embed-responsive-item" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.2285469593257!2d-0.08672711560797618!3d51.527367817064246!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761d429f1982db%3A0xae34b864089f4ebc!2sA%20QUALITY%20SCOPE%20LTD!5e0!3m2!1sar!2seg!4v1677970632710!5m2!1sar!2seg"  height={300} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              ||
              <iframe  class="embed-responsive-item" src="https://www.google.com/maps/embed?pb=!1m12!1m8!1m3!1d927762.4119396042!2d46.542343!3d24.724411!3m2!1i1024!2i768!4f13.1!2m1!1z2KfZhNix2YrYp9i2INin2YTZgtin2K_Ys9mK2Kkg2KfYtNix2YE!5e0!3m2!1sen!2ssa!4v1677969050384!5m2!1sen!2ssa"  height={300} ></iframe>
            }
        </div>
      </div>
      {/* End google-map */}
    </>
  );
};

export default Map;

import React, { Fragment, useState } from "react";
import Scrollspy from "react-scrollspy";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import { ImHome , ImOffice ,ImCalendar ,ImBriefcase ,ImAddressBook} from "react-icons/im";
import {  FaBlog } from "react-icons/fa";

const textContent = {
  en: {
    home:'Home',
    about: "About",
    blog: "Blog",
    contact: "Contact",
    work: "Work",
    resume: "Resume",
  },
  ar: {
    home:'الرئيسية',
    about: "من نحن",
    blog: "المدونة",
    contact: "اتصل بنا",
    work: "العمل",
    resume: "سيرة ذاتية",
  }
};


const Header = (prods) => {
  const { language } = prods;
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  return (
    <>
      {/* Header */}
      <div className="mob-header">
        <button className="toggler-menu" onClick={handleClick}>
          <div className={click ? "active" : ""}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
      </div>
      {/* End Header */}

      {/* nav bar */}
      <header className={click ? "header-left menu-open" : "header-left "}>
        <div className="scroll-bar">
          <div className="hl-top">
            <div className="hl-logo">
              <Link to="/">
                    <img src="img/logo.jpg" alt="about image" />
                 </Link>
            </div>
          </div>
          {/* End htl-top */}

          <Scrollspy
            className="nav nav-menu"
            items={["home", "about", "resume", "work", "blog", "contactus"]}
            currentClassName="active"
            offset={-30}
          >
            <li>
              <a
                className="nav-link "
                href="#home"
                data-tip
                data-for="HOME"
                onClick={handleClick}
              >
                <ImHome width={32} />
                <ReactTooltip id="HOME" place="top" type="dark" effect="float">
                  <span>{textContent[language].home}</span>
                </ReactTooltip>
              </a>
            </li>
            <li>
              <a
                className="nav-link"
                href="#about"
                data-tip
                data-for="ABOUT"
                onClick={handleClick}
              >
                 <ImOffice  width={32} />
                <ReactTooltip id="ABOUT" place="top" type="dark" effect="float">
                  <span>{textContent[language].about}</span>
                </ReactTooltip>
              </a>
            </li>
            <li>
              <a
                className="nav-link"
                href="#resume"
                data-tip
                data-for="Events"
                onClick={handleClick}
              >
                  <ImCalendar   width={32} />
                <ReactTooltip
                  id="RESUME"
                  place="top"
                  type="dark"
                  effect="float"
                >
                  <span>{textContent[language].resume}</span>
                </ReactTooltip>
              </a>
            </li>
            <li>
              <a
                className="nav-link"
                href="#work"
                data-tip
                data-for="Our work"
                onClick={handleClick}
              >
                 <ImBriefcase    width={32} />
                <ReactTooltip id="WORK" place="top" type="dark" effect="float">
                  <span>{textContent[language].work}</span>
                </ReactTooltip>
              </a>
            </li>
            <li>
              <a
                className="nav-link"
                href="#blog"
                data-tip
                data-for="BLOG"
                onClick={handleClick}
              >
                <FaBlog width={32} />
                <ReactTooltip id="BLOG" place="top" type="dark" effect="float">
                  <span>{textContent[language].blog}</span>
                </ReactTooltip>
              </a>
            </li>
            <li>
              <a
                className="nav-link"
                href="#contactus"
                data-tip
                data-for="CONTACT"
                onClick={handleClick}
              >
                <ImAddressBook width={32} />
                <ReactTooltip
                  id="CONTACT"
                  place="top"
                  type="dark"
                  effect="float"
                >
                  <span>{textContent[language].contact}</span>
                </ReactTooltip>
              </a>
            </li>
          </Scrollspy>
        </div>
      </header>
      {/* End Header */}
    </>
  );
};

export default Header;

import React from "react";
import Fade from "react-reveal/Fade";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaTiktok,
} from "react-icons/fa";


const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/aqulaityscope"},
  { Social: <FaTwitter />, link: "https://twitter.com/aqualityscope" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/aqualityscope/"},
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/in/dr-abdallah-abakar"},
  { Social: <FaTiktok />, link: "https://www.tiktok.com/@aqualityscope"}
];

const Footer = () => {
  return (
    <>
      <div className="row align-items-center">
        <div className="col-md-6 my-2">
          <div className="nav justify-content-center justify-content-md-start">
            <Fade bottom>
              {SocialShare.map((val, i) => (
                <a
                  key={i}
                  href={`${val.link}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  {val.Social}
                </a>
              ))}{" "}
            </Fade>
          </div>
          {/* End .nav */}
        </div>
        {/* End .col */}

        <div className="col-md-6 my-2 text-center text-md-end">
          <Fade bottom>
            <p>
              © {new Date().getFullYear()} copyright{" "}
              A Quality Scope Ltd 
              all right reserved
            </p>{" "}
          </Fade>
        </div>
        {/* End .col */}
      </div>
      <div className="whatsapp_float_icon"><a href="https://web.whatsapp.com/send/?phone=442032890900&text="><img src="./img/icons/WhatsApp.gif" /></a></div>  
      {/* End .row */}
    </>
  );
};

export default Footer;

import React from "react";

const textContent = {
  en: {
    awardContnet: [
      {
        img: "a1",
        awardName: "Golden",
        awardFor: "Choose",
      },
      {
        img: "a2",
        awardName: "Silver",
        awardFor: "Choose",
      },
      {
        img: "a3",
        awardName: "Bronze",
        awardFor: "Choose",
      },
    ]
  },
  ar: {
    awardContnet: [
      {
        img: "a1",
        awardName: "الذهبية تستهدف الشركات الحكومية والتجارية الضخمة",
        awardFor: "حجز الباقة",
      },
      {
        img: "a2",
        awardName: "الفضية تستهدف المؤسسات والمنشآت المتوسطة",
        awardFor: "حجز الباقة",
      },
      {
        img: "a3",
        awardName: "البرونزية تستهدف المؤسسات الناشئة و الافراد",
        awardFor: "حجز الباقة",
      },
    ]
  }
}
const Awards = (prods) => {
  const { language } = prods;
  return (
    <>
      <div className="row">
        {textContent[language].awardContnet.map((val, i) => (
          <div className="col-lg-4 m-15px-tb" key={i}>
            <div className="feature-box-02 d-flex align-items-center">
              <div className="icon">
                <img src={`img/award/${val.img}.png`} alt="award image" />
              </div>
              <div className="media-body">
                <h6>{val.awardName}</h6>
                <p>{val.awardFor}</p>
              </div>
            </div>
          </div>
          // End .col
        ))}
      </div>
      {/* End .row */}
    </>
  );
};

export default Awards;
